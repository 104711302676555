import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import Spacer from "../Spacer";
import { colors } from "../../constants/colors";
import HomeHeader from "./HomeHeader";
import "./Home.scss";

export default function Home() {
  return (
    <div style={{ backgroundColor: colors.white, height: "100vh" }}>
      <HomeHeader />
      <Container className="d-flex flex-column justify-content-center">
        <div className="hero">
          <Logo lg includeText className="mb-3 justify-content-center" />
          <Spacer height={25} />

          <Link to="/anonymousReport">
            <Button size="lg" className="mb-3">
              Report your concern
            </Button>
          </Link>

          <Link to="/app/login" className="mt-2">
            <p className="small mb-3">Employer Login</p>
          </Link>

          <p className="mt-4">
            This is a legacy version of Canary. Check out the newest version{" "}
            <a href="https://www.canaryatwork.com/">here</a>.
          </p>
        </div>
      </Container>
    </div>
  );
}
