import { forms } from "line3_shared/constants/forms";
import { FilterType } from "line3_shared/types/FilterType";
import React, { useEffect } from "react";
import { concernsColorPalette } from "../../../../../constants/colors";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";
import Doughnut2 from "../../../../charts/Doughnut2";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import Loader from "../../../../Loader";
import Spacer from "../../../../Spacer";

export default function HarassementAndBullyingBehaviourTab({
  updateRespondentCounter,
}) {
  const harassmentAndBullyingBehaviourFilter: FilterType = {
    or: [{ questionId: "81iLlOKLMEr2", answerId: "zHhUu9szwaqS" }],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.shortenedForm,
    questionId: "81iLlOKLMEr2",
    filter: harassmentAndBullyingBehaviourFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, data]);

  if (loading) return <Loader />;

  return (
    <div>
      <HorizontalBar2
        title="Topic of Concerns and Feedback"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          filter: harassmentAndBullyingBehaviourFilter,
          buckets: [
            {
              label: "Inappropriate comments or communication style",
              match: {
                questionId: "yr3GVOqNscZv",
                answerId: "lTmQN6nhicIp",
              },
            },
            {
              label: "Inappropriate staring or stalking",
              match: {
                questionId: "yr3GVOqNscZv",
                answerId: "PplOfEHHXphc",
              },
            },
            {
              label: "Unwanted physical contact",
              match: {
                questionId: "yr3GVOqNscZv",
                answerId: "HoETtUPcHF5K",
              },
            },
            {
              label: "Teasing and insults",
              match: {
                questionId: "Pyl7BLplQKp0",
                answerId: "wfuBqYbkMuIb",
              },
            },
            {
              label: "Gossip",
              match: {
                questionId: "Pyl7BLplQKp0",
                answerId: "agiTvWpYlkId",
              },
            },
            {
              label: "Aggressive and intimidating communication",
              match: {
                questionId: "Pyl7BLplQKp0",
                answerId: "2ZSgjkAWNJaH",
              },
            },
            {
              label: "Sexual or romantic comments",
              match: {
                questionId: "Pyl7BLplQKp0",
                answerId: "R3Dc4AVYUKyB",
              },
            },
            {
              label: "Racist, biased or discriminatory comments",
              match: {
                questionId: "Pyl7BLplQKp0",
                answerId: "sFNgYqIqCdfQ",
              },
            },
          ],
        }}
      />
      <Spacer height={40} />
      <HorizontalBar2
        title="Trangressor"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "7kKrhvt40hRq",
          filter: harassmentAndBullyingBehaviourFilter,
        }}
      />
      <Spacer height={40} />
      <HorizontalBar2
        title="Inappropriate comments related to:"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "92GXjbuZTgPw",
          filter: harassmentAndBullyingBehaviourFilter,
          excludeAnswerIds: ["UHW5MiU64iJN"], // Exclude "None of the above"
        }}
      />
      <Spacer height={40} />
      <div className="d-flex flex-row justify-content-between">
        <Doughnut2
          title={"One-time or Ongoing Issue"}
          query={{
            formId: forms.shortenedForm,
            questionId: "PpHsLIrUFKq3",
            filter: harassmentAndBullyingBehaviourFilter,
          }}
        />
        <Doughnut2
          title={"Previously Reported to Management"}
          query={{
            formId: forms.shortenedForm,
            questionId: "wIP0ordQCY7b",
            filter: harassmentAndBullyingBehaviourFilter,
          }}
        />
      </div>
    </div>
  );
}
