import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spacer from "../Spacer";
import { SOURCE_TYPE } from "../../constants/leads";
import Image from "react-bootstrap/Image";
import laptopLady from "../../static/laptop-lady-facing-right.png";
import "../FormField.scss";
import useWindowWidth from "../../hooks/useWindowWidth";

const learnMoreOptions = ["Pricing", "How Canary works", "Booking a Demo"];

const defaultLearnMoreOptionsChecked = learnMoreOptions.reduce((acc, item) => {
  return { ...acc, [item]: false };
}, {});

export default function LeadsFormForEmployers() {
  const { isMobile } = useWindowWidth();
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [learnMoreOptionsChecked, setLearnMoreOptionsChecked] = useState(
    defaultLearnMoreOptionsChecked
  );

  function onSelectedOptionsChange(event) {
    setLearnMoreOptionsChecked({
      ...learnMoreOptionsChecked,
      [event.target.name]: event.target.checked,
    });
  }

  function submitForm(event) {
    setErrors([]);

    event.preventDefault();

    const userInterestedIn = [];
    for (let key in learnMoreOptionsChecked) {
      if (learnMoreOptionsChecked[key]) {
        userInterestedIn.push(key);
      }
    }

    const formInputs = event.target.elements;

    const body = {
      name: formInputs.formBasicName.value,
      email: formInputs.formBasicEmail.value,
      job: formInputs.formBasicJob.value,
      company: formInputs.formBasicCompany.value,
      city: formInputs.formBasicCity.value,
      country: formInputs.formBasicCountry.value,
      interestedIn: userInterestedIn,
      sourceType: SOURCE_TYPE.EMPLOYER,
    };

    if (!body.email) {
      setErrors(["Email cannot be blank"]);
      return;
    }

    fetch("/api/leads", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            history.push("interestedEmployerAdditionalInfo");
          });
        } else {
          response.json().then((json) => {
            setErrors([json.message]);
          });
        }
      })
      .catch((err) => {
        setErrors(["Something went wrong. Please try again."]);
      });
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Form onSubmit={submitForm}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Row className="justify-content-center align-items-center">
            <Col xs={12} sm={6} className="d-flex justify-content-center mb-2">
              <Form.Group
                controlId="formBasicName"
                className="mb-0"
                style={{ width: 195 }}
              >
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  className="customBorder"
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={6} className="d-flex justify-content-center mb-2">
              <Form.Group
                controlId="formBasicEmail"
                className="mb-0"
                style={{ width: 195 }}
              >
                <Form.Control
                  type="text"
                  placeholder="Your Email Address"
                  className="customBorder"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} sm={6} className="d-flex justify-content-center mb-2">
              <Form.Group
                controlId="formBasicJob"
                className="mb-0"
                style={{ width: 195 }}
              >
                <Form.Control
                  type="text"
                  placeholder="Your Job"
                  className="customBorder"
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={6} className="d-flex justify-content-center mb-2">
              <Form.Group
                controlId="formBasicCompany"
                className="mb-0"
                style={{ width: 195 }}
              >
                <Form.Control
                  type="text"
                  placeholder="Your Company"
                  className="customBorder"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} sm={6} className="d-flex justify-content-center mb-2">
              <Form.Group
                controlId="formBasicCity"
                className="mb-0"
                style={{ width: 195 }}
              >
                <Form.Control
                  type="text"
                  placeholder="City"
                  className="customBorder"
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={6} className="d-flex justify-content-center mb-2">
              <Form.Group
                controlId="formBasicCountry"
                className="mb-0"
                style={{ width: 195 }}
              >
                <Form.Control
                  type="text"
                  placeholder="Country"
                  className="customBorder"
                />
              </Form.Group>
            </Col>
          </Row>
          <Spacer height={40} />
          <div className="d-flex row">
            {!isMobile && (
              <Image fluid src={laptopLady} style={{ maxHeight: 150 }} />
            )}
            <div className="d-flex flex-column align-items-left justify-content-center">
              <p>What would you like to learn more about?</p>
              {learnMoreOptions.map((option, index) => {
                return (
                  <Form.Check
                    onChange={onSelectedOptionsChange}
                    style={{ marginLeft: 60 }}
                    type="checkbox"
                    label={option}
                    name={option}
                  />
                );
              })}
            </div>
          </div>
          <Spacer height={40} />
          <Button type="submit">Submit</Button>
        </div>
      </Form>
      <div className="mt-3">
        {errors.length > 0 &&
          errors.map((error, index) => {
            return (
              <Alert key={index} variant={"danger"}>
                {error}
              </Alert>
            );
          })}
      </div>
    </div>
  );
}
