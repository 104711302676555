import React from "react";
import { forms } from "line3_shared/constants/forms";
import HorizontalBar2 from "../../../../../charts/HorizontalBar2";
import { concernsColorPalette } from "../../../../../../constants/colors";

export default function IncidentTransgressorChart({ filter }) {
  const incidentTransgressor = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Managers or Leaders",
        match: {
          or: [
            { questionId: "fbtX2dJHCDjX", answerId: "WoiwLr1skYud" }, // An individual
            { questionId: "h0sa4FYYDulH", answerId: "MIWneHKO95T3" }, // A group of people
          ],
        },
      },
      {
        label: "Employees or Contractors",
        match: {
          or: [
            { questionId: "fbtX2dJHCDjX", answerId: "hAvj85ABoBPQ" }, // An individual
            { questionId: "h0sa4FYYDulH", answerId: "BqOS78eamUDH" }, // A group of people
          ],
        },
      },
      {
        label: "Vendors or Clients",
        match: {
          or: [
            { questionId: "fbtX2dJHCDjX", answerId: "KUdeYsLEpHNq" }, // An individual
            { questionId: "h0sa4FYYDulH", answerId: "n14A6uF7vuob" }, // A group of people
          ],
        },
      },
      {
        label: "Someone else",
        match: {
          or: [
            { questionId: "fbtX2dJHCDjX", answerId: "t2HPrvS1Svgn" }, // An individual
            { questionId: "h0sa4FYYDulH", answerId: "vrRiBujBkmJU" }, // A group of people
          ],
        },
      },
    ],
    filter,
  };

  return (
    <HorizontalBar2
      title="Trangressors"
      palette={concernsColorPalette}
      query={incidentTransgressor}
    />
  );
}
