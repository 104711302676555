import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TabContainer from "../../../../TabContainer";
import OverviewTab from "./OverviewTab";
import PpeTab from "./PpeTab";
import ScreeningTab from "./ScreeningTab";
import MonitoringTab from "./MonitoringTab";
import PersonalHygieneTab from "./PersonalHygieneTab";
import CleaningAndSanitationTab from "./CleaningAndSanitationTab";
import SocialDistancingTab from "./SocialDistancingTab";
import OtherTab from "./OtherTab";
import StatementsTab from "./StatementsTab";
import Spacer from "../../../../Spacer";
import { colors } from "../../../../../constants/colors";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import {
  PROJECTS_LIST,
  PROJECTS_MAP,
} from "line3_shared/constants/covid19BuildingUp/projects";
import RespondentCounter from "../RespondentCounter";

const tabs = [
  {
    name: "Overview",
    eventKey: "overview",
    Component: OverviewTab,
  },
  {
    name: "PPE",
    eventKey: "ppe",
    Component: PpeTab,
  },
  {
    name: "Screening",
    eventKey: "screening",
    Component: ScreeningTab,
  },
  {
    name: "Monitoring",
    eventKey: "monitoring",
    Component: MonitoringTab,
  },
  {
    name: "Personal Hygiene",
    eventKey: "personalHygiene",
    Component: PersonalHygieneTab,
  },
  {
    name: "Cleaning and Sanitation",
    eventKey: "cleaningAndSanitation",
    Component: CleaningAndSanitationTab,
  },
  {
    name: "Social Distancing",
    eventKey: "socialDistancing",
    Component: SocialDistancingTab,
  },
  {
    name: "Other",
    eventKey: "other",
    Component: OtherTab,
  },
  {
    name: "Statements",
    eventKey: "statements",
    Component: StatementsTab,
  },
];

const allDatesRange = {
  startDate: "1960-01-01",
  endDate: "2100-01-01",
};

const allProjects = PROJECTS_LIST.reduce((acc, project) => {
  return { ...acc, [project.id]: true };
}, {});

export default function Dashboard() {
  const [respondentCount, setRespondentCount] = useState(0);
  const [confirmedDateRange, setConfirmedDateRange] = useState(allDatesRange);
  const [projectsFilter, setProjectsFilter] = useState(allProjects);

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);

  return (
    <TabContainer defaultActiveKey="overview" onSelect={handleSelect}>
      <Row className="px-5">
        <Col sm={3}>
          <Nav className="flex-column">
            {tabs.map((tab) => {
              return (
                <Nav.Item key={tab.eventKey}>
                  <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Spacer height={15} />
          <RespondentCounter respondentCount={respondentCount} />
          <Spacer height={20} />
          <DateRangeButtonAndModal
            setConfirmedDateRange={setConfirmedDateRange}
          />
          <Spacer height={10} />
          <ProjectButtonAndModal setProjectsFilter={setProjectsFilter} />
        </Col>
        <Col sm={9} lg={6}>
          <Tab.Content>
            {tabs.map((tab) => {
              const { Component, eventKey } = tab;
              return (
                <Tab.Pane
                  key={eventKey}
                  eventKey={eventKey}
                  mountOnEnter
                  unmountOnExit
                >
                  <Component
                    dateRange={confirmedDateRange}
                    projectsFilter={projectsFilter}
                    updateRespondentCounter={(newCount) => {
                      setRespondentCount(newCount);
                    }}
                  />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </TabContainer>
  );
}

function ProjectButtonAndModal({ setProjectsFilter }) {
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState(allProjects);
  const [projectsText, setProjectsText] = useState("All");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  function updateProjectsText() {
    const projectList = [];
    for (let projectId in projects) {
      if (projects[projectId]) {
        projectList.push(PROJECTS_MAP[projectId]);
      }
    }

    if (projectList.length === PROJECTS_LIST.LENGTH) {
      setProjectsText("All");
    } else if (projectList.length === 0) {
      setProjectsText("None");
    } else if (projectList.length === 1) {
      setProjectsText(projectList[0]);
    } else {
      setProjectsText("Multiple");
    }
  }

  function onSelectedOptionsChange(event) {
    const newProjects = {
      ...projects,
      [event.target.name]: event.target.checked,
    };

    setProjects(newProjects);
  }

  return (
    <>
      <Button
        style={{
          backgroundColor: colors.secondaryBlue,
          border: "1px solid lightgrey",
          fontSize: 12,
        }}
        onClick={handleShowModal}
      >
        Projects: {projectsText}
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Filter by Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {Object.keys(projects).map((projectId, index) => {
              return (
                <Form.Check
                  onChange={onSelectedOptionsChange}
                  type="checkbox"
                  label={PROJECTS_MAP[projectId]}
                  name={projectId}
                  checked={projects[projectId]}
                />
              );
            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="info"
            onClick={() => {
              handleCloseModal();
              setProjectsText("All");
              setProjects(allProjects);
              setProjectsFilter(allProjects);
            }}
          >
            All Projects
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleCloseModal();
              updateProjectsText();
              setProjectsFilter(projects);
            }}
          >
            Filter by Selected Projects
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function DateRangeButtonAndModal({ setConfirmedDateRange }) {
  const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [dateRangeText, setDateRangeText] = useState("All");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: colors.secondaryBlue,
          border: "1px solid lightgrey",
          fontSize: 12,
        }}
        onClick={handleShowModal}
      >
        Dates: {dateRangeText}
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Date Range Picker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            onChange={(item) => {
              setDateRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            direction="horizontal"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="info"
            onClick={() => {
              handleCloseModal();
              setDateRangeText("All");
              setConfirmedDateRange(allDatesRange);
            }}
          >
            All Dates
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleCloseModal();
              const startDate = dateRange[0].startDate
                .toISOString()
                .slice(0, 10);
              const endDate = dateRange[0].endDate.toISOString().slice(0, 10);
              setDateRangeText(`${startDate} to ${endDate}`);
              setConfirmedDateRange({
                startDate,
                endDate,
              });
            }}
          >
            Apply Selected Dates
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
