import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineLogin } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import "./HomeHeader.scss";
import useWindowWidth from "../../hooks/useWindowWidth";
import cx from "classnames";
import useScrollPosition from "@react-hook/window-scroll";
import Logo from "../Logo";

export default function HomeHeader() {
  const scrollY = useScrollPosition(60 /*frames per second*/);
  const opacity = Math.max(0, scrollY - 25) / 300;
  const fullInfo = opacity > 1;

  const { isMobile } = useWindowWidth();

  return (
    <div
      className="fixed-top HomeHeader"
      style={{
        background: `rgba(255, 255, 255, ${opacity})`,
        borderBottom: `solid rgba(244, 147, 3, ${opacity / 2}) 3px`,
      }}
    >
      {fullInfo && !isMobile && (
        <div className="logoWrapper">
          <div className="logoInnerWrapper">
            <Logo sm includeText className="justify-content-start" />
          </div>
        </div>
      )}
      {fullInfo && (
        <div className={cx("reportButton", { mobile: isMobile })}>
          <Link to="/anonymousReport">
            <Button variant="primary">Report your concern</Button>
          </Link>
        </div>
      )}

      <div className="d-flex justify-content-end loginButton">
        <Link to="/app/login" className="d-flex flex-row align-items-center">
          <p className="mb-0 mr-2">Login</p>
          <AiOutlineLogin />
        </Link>
      </div>
    </div>
  );
}
