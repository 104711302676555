import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Container, Col, Alert } from "react-bootstrap";
import BackArrow from "../BackArrow";
import { colors } from "../../constants/colors";
import "../FormField.scss";

export default function EmployerLogin() {
  let history = useHistory();
  const [errors, setErrors] = useState([]);

  function submitForm(event) {
    event.preventDefault();

    const formInputs = event.target.elements;
    const body = {
      email: formInputs.formBasicEmail.value,
      password: formInputs.formBasicPassword.value,
    };

    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          history.push("/app");
        } else {
          response.json().then((json) => {
            setErrors([json.message]);
            setTimeout(() => {
              setErrors([]);
            }, 3000);
          });
        }
      })
      .catch((err) => {
        setErrors(["Login failed. Please try again."]);
        setTimeout(() => {
          setErrors([]);
        }, 3000);
      });
  }

  return (
    <div style={{ backgroundColor: colors.white }}>
      <Container className="d-flex vh-100 justify-content-center align-items-center">
        <Col
          xs={12}
          sm={6}
          md={4}
          className="d-flex flex-column align-items-center"
        >
          <div style={{ height: "100px" }} />
          <h3 className="mb-5">Employer Login</h3>
          <Form onSubmit={submitForm}>
            <div className="d-flex flex-column" style={{ minWidth: 250 }}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="example@company.com"
                  autoFocus="autofocus"
                  className="customBorder"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="customBorder"
                />
              </Form.Group>

              <Button type="submit">Sign in</Button>
            </div>
          </Form>
          <div className="mt-3" style={{ height: "100px" }}>
            {errors.length > 0 &&
              errors.map((error, index) => {
                return (
                  <Alert key={index} variant={"danger"}>
                    {error}
                  </Alert>
                );
              })}
          </div>
        </Col>
      </Container>
    </div>
  );
}
