import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import BackArrow from "../BackArrow";
import { colors } from "../../constants/colors";
import Logo from "../Logo";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function InterestedEmployerAdditionalInfo() {
  return (
    <div style={{ backgroundColor: colors.white }}>
      <BackArrow to="/interestedEmployer" />
      <Container className="d-flex vh-100 flex-column justify-content-center align-items-center">
        <Logo lg />
        <Row>
          Would you like to tell us (anonymously) about how concerns are handled
          at your company?
        </Row>
        <Row className="mt-5">
          <Link to="/freeWorkplaceSurvey">
            <Button className="mr-4">Sure</Button>
          </Link>
          <Link to="/">
            <Button variant="outline-dark">No thanks</Button>
          </Link>
        </Row>
      </Container>
    </div>
  );
}
