import React from "react";
import { Image } from "react-bootstrap";
import colorLogoWithText from "../static/canary-logo-full@3x.png";
import colorLogoWithoutText from "../static/canary-1@3x.png";

export default function Logo({ xs, sm, md, lg, size, includeText, className }) {
  let logoHeight = size || 75;
  if (xs) {
    logoHeight = 35;
  }
  if (sm) {
    logoHeight = 75;
  }
  if (md) {
    logoHeight = 100;
  }
  if (lg) {
    logoHeight = 160;
  }

  const logoWidth = includeText ? logoHeight * 2.75 : logoHeight;
  const logoImage = includeText ? colorLogoWithText : colorLogoWithoutText;

  return (
    <div
      className={`d-inline-flex ${className}`}
      style={{ height: logoHeight, width: logoWidth }}
    >
      <Image src={logoImage} fluid />
    </div>
  );
}
