import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Logo from "./Logo";
import useWindowWidth from "../hooks/useWindowWidth";
import { colors } from "../constants/colors";
import "./Footer.scss";

export default function Footer() {
  const isHomePage = useRouteMatch({
    path: "/",
    exact: true,
    strict: true,
    sensitive: true,
  });

  const isReportPage = useRouteMatch({
    path: "/org/:orgId/report",
    strict: true,
    sensitive: true,
  });

  const isDashboardPage = useRouteMatch({
    path: "/app",
    exact: true,
    strict: true,
    sensitive: true,
  });

  const { isMobile } = useWindowWidth();

  if (isHomePage || isReportPage || isDashboardPage || isMobile) {
    return null;
  }

  return (
    <div
      className="Footer"
      // className="d-flex flex-row align-items-center justify-content-center"
      style={{ height: "2em", backgroundColor: colors.white }}
    >
      <div className="d-flex flex-row align-items-center flex-grow-1">
        <Logo xs className="mb-1 justify-content-start" />
        <p className="mb-0">info@canary.report</p>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center flex-grow-1">
        <Link to="/terms" className="ml-4">
          <p className="small mb-0">Terms of Service</p>
        </Link>
        <Link to="/privacy" className="ml-4">
          <p className="small mb-0">Privacy Policy</p>
        </Link>
        {/*<SecurityPopup />*/}
      </div>
    </div>
  );
}
