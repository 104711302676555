// Note: chart color schemes chosen from here: https://nagix.github.io/chartjs-plugin-colorschemes/colorchart.html
// chartCategorical corresponds to tableau.HueCircle19
// chartRedGreen corresponds to tableau.RedGreen7

const colors = {
  primary: "#ffcb4b",
  secondaryBlue: "#a6cee1",
  accentOrange: "#f69668",
  lightPeach: "#fcdbcb",
  papayawhip: "papayawhip",
  white: "#FFFFFF",
  chartCategorical1: "#1BA3C6",
  chartCategorical2: "#2CB4C0",
  chartCategorical3: "#30BCAD",
  chartCategorical4: "#20B087",
  chartCategorical5: "#32A55C",
  chartCategorical6: "#57A336",
  chartCategorical7: "#A2B626",
  chartCategorical8: "#D5BB20",
  chartCategorical9: "#F8B620",
  chartCategorical10: "#F8B620",
  chartCategorical11: "#F06719",
  chartCategorical12: "#E03325",
  chartCategorical13: "#F64971",
  chartCategorical14: "#FC719E",
  chartCategorical15: "#EB73B3",
  chartCategorical16: "#CE69BE",
  chartCategorical17: "#A26DC2",
  chartCategorical18: "#7873C0",
  chartCategorical19: "#4F7CBA",
  chartRedGreen1: "#AE123B",
  chartRedGreen2: "#EE574D",
  chartRedGreen3: "#FDAC9E",
  chartRedGreen4: "#91D282",
  chartRedGreen5: "#539E52",
  chartRedGreen6: "#24683D",
};

const concernsColorPalette = [
  colors.chartCategorical1,
  colors.chartCategorical2,
  colors.chartCategorical3,
  colors.chartCategorical4,
  colors.chartCategorical5,
  colors.chartCategorical6,
  colors.chartCategorical7,
  colors.chartCategorical8,
  colors.chartCategorical9,
  colors.chartCategorical10,
  colors.chartCategorical11,
  colors.chartCategorical12,
  colors.chartCategorical13,
];

const covid19Colors = {
  ppe: colors.chartCategorical1,
  screening: colors.chartCategorical3,
  monitoring: colors.chartCategorical5,
  personalHygiene: colors.chartCategorical7,
  cleaningAndSanitation: colors.chartCategorical9,
  socialDistancing: colors.chartCategorical11,
  other: colors.chartCategorical13,
};

export { colors, covid19Colors, concernsColorPalette };
