import React from "react";
import { colors } from "../../../constants/colors";
import "./Pricing.scss";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

export default function Pricing() {
  return (
    <div
      className="pricingContainer"
      style={{ backgroundColor: colors.lightPeach }}
    >
      <div style={{ maxWidth: 760, padding: 15, margin: "auto" }}>
        <div className="pricingSummary">
          <div className="pricingSummarySentence">
            Canary's basic plan starts at $99/month or $1000/year.
          </div>
          <div className="pricingSummarySentence">
            Additional costs for customization, setup and extra features may
            apply.
          </div>
          <div className="pricingSummarySentence">
            Discounted pricing is available for non-profits and educational
            organizations.
          </div>
        </div>
        <div className="pricingLearnMore">
          <p>
            To learn more about how we could be of service to your organization,
            schedule your complimentary, no obligation consultation now for more
            information and a demo.
          </p>
        </div>
        <Row>
          <Col>
            <Link to="/bookConsultation">
              <Button size="lg" className="mb-3" style={{ width: 220 }}>
                Book a Consultation
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}
