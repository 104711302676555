import React, { useState } from "react";
import "./Statements.scss";
import Button from "react-bootstrap/Button";
import DateTime from "luxon/src/datetime";

export default function Statements({ statements }) {
  const [statementIndex, setStatementIndex] = useState(0);

  if (statements.length === 0) {
    return (
      <div className="statementsWrapper">
        <div />
        <div className="statement">
          <p>No statements</p>
        </div>
        <div />
      </div>
    );
  }

  function incrementIndex() {
    if (statementIndex < statements.length - 1) {
      setStatementIndex(statementIndex + 1);
    }
  }
  function reduceIndex() {
    if (statementIndex > 0) {
      setStatementIndex(statementIndex - 1);
    }
  }
  const statement = statements[statementIndex];
  const formattedDate = DateTime.fromISO(statement.date).toLocaleString(
    DateTime.DATE_FULL
  );

  return (
    <div className="statementsWrapper">
      <div />
      <div />
      <div />
      <div className="statement">
        <p>"{statement.statement}"</p>
      </div>
      <div className="statement">
        <p>{statement.email || "Anonymous"}</p>
        <p>{formattedDate}</p>
      </div>
      <div className="buttonsRow">
        <Button onClick={reduceIndex} disabled={statementIndex === 0}>
          Previous
        </Button>
        <Button
          onClick={incrementIndex}
          disabled={statementIndex >= statements.length - 1}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
